<div [class.attention]="yellowHeader" class="message-modal">
  <div class="message-modal__head"></div>
  <div class="message-modal__body">
    <div class="message-modal__title">{{ yellowHeader ? 'Attention' : 'Success' }}</div>
    <div class="message-modal__text">{{ message }}</div>
  </div>
  <div class="message-modal__buttons">
    <button
      *ngIf="isBtnLoginForAdmission"
      (click)="loginTOCalendar()"
      [disabled]="isLogin || disabledButtons.loginToCalendar"
      [class.attention]="yellowHeader"
      class="btn">
      Login to calendar
    </button>
    <button
      *ngIf="isBtnClose && !isBtnLoginForAdmission"
      (click)="close()"
      [class.attention]="yellowHeader"
      [class.red]="isBtnLoginForAdmission"
      [disabled]="disabledButtons.close"
      data-e2e="message-popup-close-btn"
      class="btn">
      {{ isBtnLoginForAdmission ? 'Cancel' : closeBtnText }}
    </button>
  </div>
</div>
